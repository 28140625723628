<template>
  <df-modal
    persistent
    :action-name="$t('SowingsPlanning.request_genetic_modal.request')"
    :loading="loading"
    :title="$t('SowingsPlanning.request_genetic_modal.request_genetic')"
    @action-click="requestGenetic"
    @close="closeRequestGeneticModal"
  >
    <template #card-content>
      <v-form
        data-id="request-genetic-modal"
        ref="requestGeneticForm"
        class="df-flex-xl df-flex-col"
      >
        <df-input
          v-model="geneticName"
          :label="$t('SowingsPlanning.request_genetic_modal.genetic')"
          :placeholder="
            $t('SowingsPlanning.request_genetic_modal.insert_genetic_name')
          "
          :rules="[required]"
        />
        <df-input
          v-model="companyName"
          :label="$t('SowingsPlanning.request_genetic_modal.company')"
          :placeholder="
            $t('SowingsPlanning.request_genetic_modal.insert_company_name')
          "
        />
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { required } from '@/utils/formRules'

export default {
  name: 'RequestGeneticModal',

  components: {
    DfInput,
    DfModal,
  },

  data() {
    return {
      companyName: null,
      geneticName: null,
      loading: false,
      required,
    }
  },

  methods: {
    closeRequestGeneticModal() {
      this.$emit('close')
    },
    async requestGenetic() {
      if (!this.$refs.requestGeneticForm.validate()) return
      this.loading = true
      try {
        const params = {
          company_name: this.companyName,
          variety_name: this.geneticName,
        }
        await sowingsPlanningService.requestGenetic(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t(
            'SowingsPlanning.request_genetic_modal.successful_request_title'
          ),
          this.$t(
            'SowingsPlanning.request_genetic_modal.successful_request_text'
          )
        )
      } catch (error) {
        console.error(error)
        this.$root.$emit(
          'notify',
          'error',
          this.$t('exception.unexpected_error_title'),
          this.$t('SowingsPlanning.exception.request_genetic')
        )
      } finally {
        this.$emit('close-both-modals')
        this.loading = false
      }
    },
  },
}
</script>
